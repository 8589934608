import { html, LitElement, PropertyValueMap } from 'lit';
import {customElement, property, state, queryAssignedElements} from 'lit/decorators.js';
import "element-internals-polyfill";
import { Loader } from './template/loader';
import { baseCss } from './styles/base';
import { minimalProductCss } from './styles/minimal-product';
import { loaderCss } from './styles/loader';
import { ViProduct } from './vi-product';
import { ViPayment } from './vi-payment';
import { FunctionalComponent } from './model/functional-component';
import { MinimalProduct, MinimalProductDecline, MinimalProductSelectAll } from './template/minimal-product';
import { appendExternalStylesheet, productCoverageMaximum } from './util';
import { when } from 'lit/directives/when.js';
import { LegalDisclaimer } from './template/legal-disclaimer';
import { RadioButtonProduct } from './template/radio-button-product';
import { radioButtonProductCss } from './styles/radio-button-product';
import { TabProduct } from './template/tab-product';
import { tabProductCss } from './styles/tab-product';
import { toggleProductCss } from './styles/toggle-product';
import { ToggleProduct } from './template/toggle-product';
import { Customer } from './model/customer';

enum ComponentDisplayType {
  simple,
  tab,
  radio,
  toggle
}

const ProductDisplayComponent: {[key in ComponentDisplayType]: FunctionalComponent} = {
  [ComponentDisplayType.simple]: MinimalProduct,
  [ComponentDisplayType.tab]: TabProduct,
  [ComponentDisplayType.radio]: RadioButtonProduct,
  [ComponentDisplayType.toggle]: ToggleProduct
}

const displayProductCoverageExceeded = ['shipping'];

/**
 * @event {CustomEvent} change - Event fired when quote data changes due to a change in policy attributes.
 * @tag vi-quote
 * @tagname vi-quote
 */
@customElement('vi-quote')
class ViQuote extends LitElement {
  /**
   * Partner Client Id (see https://docs.verticalinsure.com/docs/authentication)
   * @type {string}
   */
  @property({attribute: "client-id"}) clientId: string;

  /**
   * Customer first name
   * @type {string | undefined}
   */
  @property({attribute: "customer-first-name"}) customerFirstName: string;

  /**
   * Customer last name
   * @type {string | undefined}
   */
  @property({attribute: "customer-last-name"}) customerLastName: string;

  /**
   * Customer email address
   * @type {string | undefined}
   */
  @property({attribute: "customer-email-address"}) customerEmailAddress: string;

  /**
   * Customer street name
   * @type {string | undefined}
   */
  @property({attribute: "customer-address-street"}) customerAddressStreet: string;

  /**
   * Customer city 
   * @type {string | undefined}
   */
  @property({attribute: "customer-address-city"}) customerAddressCity: string;

  /**
   * Customer state
   * @type {string | undefined}
   */
  @property({attribute: "customer-address-state"}) customerAddressState: string;

  /**
   * Customer postal code
   * @type {string | undefined}
   */
  @property({attribute: "customer-address-postal-code"}) customerAddressPostalCode: string;

  /**
   * Hides the Vertical Insure logo within the legal disclaimer
   * @type {boolean}
   */
  @property({attribute: "hide-logo", type: Boolean}) hideLogo: boolean = false;

  /**
   * Hides all product icons in certain views
   * @type {boolean}
   */
  @property({attribute: 'hide-product-icons', type: Boolean}) hideProductIcons: boolean = false;

  @property({attribute: 'include-payment-element', type: Boolean}) includePaymentElement: boolean = false;
  @property({attribute: 'customer-client-secret'}) customerClientSecret: string;
  @property({attribute: 'selected-by-default', type: Boolean}) selectedByDefault: boolean = false;
  @property({attribute: 'display-auto-enable', type: Boolean}) displayAutoEnable: boolean = false;

  @state() premium: Number;
  @state() selectedProducts: Array<any> = undefined;
  @state() loading: boolean = true;
  @state() customer: Customer;
  @state() paymentMethod: string;
  @state() products: Array<ViProduct> = [];
  @state() componentDisplayType: ComponentDisplayType;
  @state() displayComponent: FunctionalComponent;
  @state() requiredInput: boolean = false;
  @state() invalid: boolean = false;
  @state() errorMessage: string;
  @state() error: boolean = false;

  /**
   * @private
   */
  declare internals: ElementInternals;

  /**
   * @private
   */
  @queryAssignedElements()
  productElements!: Array<ViProduct>;

  static override styles = [
    baseCss,
    minimalProductCss, 
    radioButtonProductCss,
    tabProductCss,
    loaderCss,
    toggleProductCss
  ]

  /**
   * @private
   */
  static get formAssociated() {
    return true;
  }

  constructor() {
    super();
    this.products = [];
    this.loading = true;
    this.hideLogo = false;
    this.internals = this.attachInternals();

    this.addEventListener("registerProduct",  ((event: CustomEvent<ViProduct>) => {
      this.productionRegistrationHandler(event);
    }) as EventListener);

    this.addEventListener('invalid', (e) => {
      this.invalid = true;
      e.preventDefault();
    });
  }

  protected createRenderRoot() {
    const root = super.createRenderRoot();
    root.addEventListener("paymentMethodSelected",  ((event: CustomEvent<ViPayment>) => {
      this.paymentMethodSelectedHandler(event);
    }) as EventListener);

    this.addEventListener('quoteUpdate', () => {
      this.requestUpdate();
      this.handleChangeEvent();
    });

    this.addEventListener('productDisplayUpdate', () => {
      this.requestUpdate();
    });

    this.addEventListener('loadingUpdate', () => {
      this.productLoadingHandler();
    });
    
    return root;
  }

  override connectedCallback(): void {
    super.connectedCallback();
    
    if (!this.hideProductIcons) {
      appendExternalStylesheet('https://fonts.googleapis.com/css?family=Material+Icons');
    }

    appendExternalStylesheet('https://fonts.googleapis.com/css?family=Playfair+Display');
    this.setFormData();

    this.customer = {
      first_name: this.customerFirstName,
      last_name: this.customerLastName,
      email_address: this.customerEmailAddress,
      state: this.customerAddressState,
      city: this.customerAddressCity,
      street: this.customerAddressStreet,
      postal_code: this.customerAddressPostalCode
    }
  }

  protected override updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this.productElements?.length > 0 && Array.from(_changedProperties.keys()).some(i => [
      "clientId", 
      "customerFirstName", 
      "customerLastName", 
      "customerAddressStreet", 
      "customerAddressState", 
      "customerAddressCity", 
      "customerAddressPostalCode"
    ].includes(i.toString()) && _changedProperties.get(i) !== undefined)) {
      this.configureSlotProducts();
    } 
  }

  private handleSlotchange() {
    this.configureSlotProducts();
  }

  private configureSlotProducts(e?: any | undefined) {
    if (!this.loading) {
      this.loading = true;
    } 

    var childNodes: Array<ViProduct>;

    if (e === undefined) {
      childNodes = this.productElements;
    } else {
      childNodes = e.target.assignedElements();
    }

    if (childNodes.length === 0) {
      this.loading = false;
      this.error = true;
      this.errorMessage = 'No product has been configured to display for this quote.'
    } else {
      this.error = false;
      this.errorMessage = undefined;
    }

    childNodes.forEach((element, index) => {
      element.clientId = this.clientId;
      element.order = index;
      element.customer = this.getCustomer();
    });

    this.configureComponentDisplay();
  }

  private configureComponentDisplay() {
    this.componentDisplayType = this.getComponentDisplayType();
    this.displayComponent = ProductDisplayComponent[this.componentDisplayType];

    if (this.componentDisplayType !== ComponentDisplayType.toggle) {
      this.requiredInput = true;
    }
  }

  private getCustomer(): any { 
    return {
      first_name: this.customerFirstName,
      last_name: this.customerLastName,
      email_address: this.customerEmailAddress,
      state: this.customerAddressState,
      city: this.customerAddressCity,
      street: this.customerAddressStreet,
      postal_code: this.customerAddressPostalCode
    }
  }

  /**
   * @returns a Promise resolving to a tokenized payment method.
   */
  public getPaymentMethod() {
    let viPaymentElement = this.shadowRoot.querySelector("vi-payment");

    if (viPaymentElement) {
      return viPaymentElement.getPaymentMethod();
    } else {
      return new Promise((resolve) => resolve(null));
    }
  }

  public getData() {
    const data = {
      vi_quotes: this.selectedProducts?.map(p => p?.quote.quote_id),
      vi_premium: this.selectedProducts?.reduce((accumulator, p) => accumulator + p?.quote.premium_amount, 0),
      vi_payment_method: this.paymentMethod
    }
    return data;
  }

  override render() {
    let shouldDisplay = this.productElements.some(i => i.hidden === false);
    let visibleProducts = this.products?.filter(i => i.hidden === false);
    let shouldCreatePaddingBlock = this.products?.some(i => displayProductCoverageExceeded.includes(i.product));

    return html`
      ${when(shouldDisplay, () => html`
        <div class="vi-component-wrapper ${this.loading ? 'min-block-size' : ''}">
          ${when(this.invalid, () => html`
            <div class="invalid-message">A selection is required.</div>
          `)}
          ${when(this.error, () => html`
            <div class="vi-error">
              <p>${this.errorMessage}</p>
            </div>
          `, () => html`
            <div class="vi-quote-wrapper">
              ${when(this.loading, () => html`${Loader()}`)}
              <div class="vi-product-choice-view ${shouldCreatePaddingBlock ? 'min-block-size' : ''}">  
                ${visibleProducts?.map((p) => {

                  let coverageExceededContent = this.loading ? undefined : p.querySelector('div[slot="coverage-exceeded"]')?.innerHTML;
                  let additionalContent = this.loading ? undefined : p.querySelector('div[slot="additional-content"]')?.innerHTML;

                  return this.displayComponent({
                    showProductIcon: !this.hideProductIcons, 
                    productDetails: p.productDetails, 
                    quote: p.quote, 
                    policyAttributes: p.policyAttributes,
                    onSelect: () => this.selectProduct(p), 
                    onDecline: () => this.selectNone(),
                    includePaymentElement: this.includePaymentElement,
                    selected: this.selectedProducts === undefined ? undefined : (this.selectedProducts.indexOf(p) > -1 && this.selectedProducts.length == 1? true : false),
                    protectionAmount:  p.policyAttributes.insurable_amount || p.policyAttributes.declared_value,
                    maxProtectionAmount: productCoverageMaximum(p.product, p.policyAttributes),
                    clientId: this.clientId,
                    customer: this.getCustomer(),
                    customerClientSecret: this.customerClientSecret,
                    displayAutoEnable: this.displayAutoEnable,
                    selectedByDefault: this.selectedByDefault,
                    errors: p.errors,
                    coverageExceededContent: coverageExceededContent,
                    invalid: this.invalid,
                    additionalContent: additionalContent,
                    toggleAutoEnable: () => {
                      this.selectedByDefault = !this.selectedByDefault;
                    }
                  });
                })}
              
                ${when(this.products.length > 1, () => html`
                  ${MinimalProductSelectAll(this.products, this.selectedProducts?.length == this.products.length, !this.hideProductIcons, this.selectAll)}
                `)}
              </div>
              
              ${when(this.componentDisplayType === ComponentDisplayType.simple, () => html`
                ${MinimalProductDecline(this.products, this.selectedProducts?.length == 0, this.selectNone)}
              `)}
            </div>
            
            ${LegalDisclaimer(this.products, !this.hideLogo)}
          `)}
        </div>
      `)}

      <slot @slotchange=${this.handleSlotchange}></slot>
    `;
  }

  private productionRegistrationHandler(event: CustomEvent<ViProduct>) {
    let newProducts = [...this.products];
    newProducts[event.detail.order] = event.detail;
    this.products = newProducts;

    if (!this.requiredInput) {
      this.selectedProducts = [];
    }

    if (this.selectedByDefault) {
      this.selectedProducts = [this.products[0]];
    }

    this.handleChangeEvent();
  }

  private productLoadingHandler() {
    if (!this.productElements.some(i => i.loading == true)) {
      this.loading = false;
    } else if (!this.loading) {
      this.loading = true;
    }
  }

  private paymentMethodSelectedHandler(event: CustomEvent<ViPayment>) {
    this.paymentMethod = event.detail.paymentMethod.card?.token || event.detail.paymentMethod.bank_account?.token;
    this.handleChangeEvent();
  }
  
  private getComponentDisplayType(): ComponentDisplayType | undefined {
    if (this.productElements.length > 1) {
      return ComponentDisplayType.simple;
    } else if (this.productElements.length === 1) {
      if (['event-host-insurance'].includes(this.productElements[0].product)) {
        return ComponentDisplayType.tab;
      } else if (['shipping'].includes(this.productElements[0].product)) {
        return ComponentDisplayType.toggle;
      } else {
        return ComponentDisplayType.radio;
      }
    } else {
      return undefined;
    }
  }

  public reportValidity(): boolean {
    return this.internals.reportValidity();
  }

  public checkValidity(): boolean {
    return this.internals.checkValidity();
  }

  private selectProduct(product: ViProduct) {
    this.selectedProducts = [product];
    this.handleChangeEvent();
  }

  private selectAll() {
    this.selectedProducts = this.products;
    this.handleChangeEvent();
  }

  private selectNone() {
    this.selectedProducts = [];
    this.handleChangeEvent();
  }

  private handleChangeEvent() {
    this.setFormData();
    
    if (this.requiredInput && this.products.length > 0 && this.products.some(p => p.quote !== undefined)) {
      if (this.selectedProducts === undefined) {
        this.internals.setValidity({'valueMissing':true}, "A selection is required.");
      } else {
        this.invalid = false;
        this.internals.setValidity({});
      }
    }

    let selectedProductsWithQuotes = this.selectedProducts?.filter(p => p?.quote !== undefined) || []; 

    this.dispatchEvent(new CustomEvent("change", {
      detail: {
        quotes: selectedProductsWithQuotes.map(p => p.quote.quote_id),
        premium: selectedProductsWithQuotes.reduce((accumulator, p) => accumulator + p?.quote.premium_amount, 0),
        paymentMethod: this.paymentMethod,
        selectedByDefault: this.selectedByDefault
      }, 
      bubbles: true
    }));
  }

  private setFormData() {
    const formData = new FormData();
    this.selectedProducts?.forEach(p => {
      let quote_id = p.quote?.quote_id;
      if (quote_id !== undefined) {
        formData.append('vi_quote', quote_id);
      }
    });

    if (this.paymentMethod !== undefined) {
      formData.append('vi_payment_method', this.paymentMethod);
    }

    this.internals.setFormValue(formData);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'vi-quote': ViQuote
  }
}
export { ViQuote, ViProduct, ViPayment }