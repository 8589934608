import { html } from "lit";
import { when } from 'lit/directives/when.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import { formatDollar } from "../util.js";
import { FunctionalComponent } from "model/functional-component.js";
import { AutoEnableAction } from "./auto-enable-action.js";

export const ToggleProduct: FunctionalComponent = ({
  showProductIcon, 
  selected, 
  onSelect, 
  onDecline, 
  productDetails, 
  quote, 
  policyAttributes,
  customer, 
  includePaymentElement, 
  clientId, 
  customerClientSecret,
  displayAutoEnable,
  toggleAutoEnable,
  selectedByDefault,
  protectionAmount,
  maxProtectionAmount,
  coverageExceededContent,
  additionalContent
}) => {

  function toggle(e) {
    if (e.target.checked) {
      onSelect();
    } else {
      onDecline();
    }
  }

  return html`
    <div class="toggle-product-wrapper">
      ${when(maxProtectionAmount < protectionAmount, () => html`
        <div class="product">
          ${when(showProductIcon, () => html`<div class="icon"><span class="material-icons md-48">${productDetails.promotional_icon}</span></div>`, () => '')}
          <div class="title">${productDetails.friendly_name}</div>
          <div class="description">This item exceeds the maximum coverage limit of ${formatDollar(maxProtectionAmount)}</div>
          ${when(coverageExceededContent, () => html`<div class="description">${unsafeHTML(coverageExceededContent)}</div>`)}
        </div>
      `, () => html`
        <div class="product">
          ${when(showProductIcon, () => html`<div class="icon"><span class="material-icons md-48">${productDetails.promotional_icon}</span></div>`, () => '')}
          <div class="title">Include ${productDetails.friendly_name}</div>
          <div class="description">
            ${unsafeHTML(productDetails.promotional_description)} ${when(productDetails.suffixContent, () => unsafeHTML(productDetails.suffixContent))}
            ${when(additionalContent, () => unsafeHTML(additionalContent))}
          </div>
          <div class="toggle-action">
            <div class="checkbox-wrapper-4">
              <input class="inp-cbx" id="select-product" type="checkbox"  ?checked=${selected} @change=${toggle}/>
              <label class="cbx" for="select-product">
                <span>
                  <svg>
                    <use xlink:href="#check-4"></use>
                  </svg>
                </span>
                <span>Yes, I want to purchase <strong> ${productDetails.friendly_name}</strong></span>
              </label>
              <svg class="inline-svg">
                <symbol id="check-4" viewbox="0 0 12 10">
                  <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </symbol>
              </svg>
            </div>
          </div>
          ${when(includePaymentElement && selected, () => html`
            <div>
              <vi-payment .customer=${customer} .clientId=${clientId} .customerClientSecret=${customerClientSecret}></vi-payment>
            </div>
            ${when(displayAutoEnable, () => AutoEnableAction(productDetails, selectedByDefault, toggleAutoEnable))}
          `)}
          <div class="price">${formatDollar(quote?.premium_amount)}</div>
        </div>
      `)}
    </div>
  `;
}