import dayjs from "dayjs/esm/index.js";
import utc from "dayjs/esm/plugin/utc/index.js";
import timezone from "dayjs/esm/plugin/timezone/index.js";
import advancedFormat from "dayjs/esm/plugin/advancedFormat/index.js";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const formatDollar = function(amount: number) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  if (amount) {
    let decimalAmount = amount / 100;
    return formatter.format(decimalAmount);
  } 

  return formatter.format(0);
}

export const formatDate = function(date: Date) {
  return dayjs(date).format('MM/DD/YYYY hh:mm a z');
}


const isObject = (object: any) => {
  return object != null && typeof object === "object";
};

export const isDeepEqual = (object1: any, object2: any) => {

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const getContrastColor = function (hexcolor: string){

	// If a leading # is provided, remove it
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1);
	}

	// If a three-character hexcode, make six-character
	if (hexcolor.length === 3) {
		hexcolor = hexcolor.split('').map(function (hex) {
			return hex + hex;
		}).join('');
	}

	// Convert to RGB value
	var r = parseInt(hexcolor.substr(0,2),16);
	var g = parseInt(hexcolor.substr(2,2),16);
	var b = parseInt(hexcolor.substr(4,2),16);

	// Get YIQ ratio
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	// Check contrast
	return (yiq >= 128) ? 'black' : 'white';

};

export const appendExternalStylesheet = function(fontCssUrl) {
  let element = document.querySelector(`link[href="${fontCssUrl}"]`);

  // Only inject the element if it's not yet present
  if (!element) {
    element = document.createElement('link');
    element.setAttribute('rel', 'stylesheet');
    element.setAttribute('href', fontCssUrl);
    document.head.appendChild(element);
  }
}

export const parseJwt = function(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const productCoverageMaximum = function(product, policyAttributes) {
  let maxProtectionAmount = undefined;

  if (product === 'shipping') {
    maxProtectionAmount = policyAttributes.courier_id == '3' ? 200000 : 2000000;
  } else if (product === 'event-participation-cancellation-insurance') {
    maxProtectionAmount = 500000
  }

  return maxProtectionAmount;
}